import { isEmpty, isEqual } from 'lodash';
import { IAsset } from '../../../interfaces';
import { prodBaseUrlV2 } from '../../config';
import { apigClient } from '../aws';

export const saveAsset = async (input: IAsset) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/libraries/v1/assets`;
  const method = 'POST';
  const result = await client.invokeApi({}, path, method, {}, input);
  return result.data;
};

export type SearchAssetOptions = {
  userId: string;
  keywords?: string[];
  size?: number;
  startIndex?: number;
  page?: number;
  categories?: string[];
  sortKey?: 'title' | 'sizekb' | 'createdat';
  sortOrder?: 'asc' | 'desc';
  status?: 'activated' | 'deleted' | 'private' | 'permanently_deleted';
  assetIds?: string[];
  assetTypes?: string[];
};

export const cachedUpdatedAssets: {
  [id: string]: {
    prev: IAsset;
    post: IAsset;
    lastCache: number;
  };
} = {};

let _checkSyncTimer;

export const cacheUpdatedAsset = (item: IAsset, beforeItem: IAsset) => {
  cachedUpdatedAssets[item.id] = {
    post: item,
    prev: beforeItem,
    lastCache: Date.now()
  };

  if (!_checkSyncTimer) {
    _checkSyncTimer = setInterval(() => {
      const allCachedAssets = Object.values(cachedUpdatedAssets).map(
        (cache) => cache.post
      );
      searchAssets({
        userId: item.createdBy,
        assetIds: allCachedAssets.map((a) => a.id)
      }).then((data) => {
        const isAllSynced = allCachedAssets.every((cachedAsset) => {
          const assetInSearchDb = data.assets.find(
            (a) => a.id === cachedAsset.id
          );
          const isUpdated = isEqual(cachedAsset, assetInSearchDb);
          if (isUpdated) {
            delete cachedUpdatedAssets[cachedAsset.id];
            return true;
          }
        });

        if (isAllSynced) {
          clearInterval(_checkSyncTimer);
          _checkSyncTimer = undefined;
        }
      });
    }, 1500);
  }
};

export const clearOutdatedCachedAsset = () => {
  Object.keys(cachedUpdatedAssets).forEach((id) => {
    if (Date.now() - cachedUpdatedAssets[id].lastCache > 20000) {
      // take long time to sync when the asset has multiple updates
      delete cachedUpdatedAssets[id];
    }
  });
  if (isEmpty(cachedUpdatedAssets)) {
    clearInterval(_checkSyncTimer);
    _checkSyncTimer = undefined;
  }
};

export const hasCachedAssets = () => !isEmpty(cachedUpdatedAssets);

const isMatched = (asset: IAsset, searchOptions: SearchAssetOptions) => {
  if (searchOptions.status === 'deleted') {
    return asset.status === 'deleted';
  }
  if (asset.status.includes('deleted')) {
    return false;
  }
  let matched = true;
  if (!isEmpty(searchOptions.keywords)) {
    const keyString = searchOptions.keywords.join(' ');
    const isContainSearchKeywords = asset.title.includes(keyString);
    const isTagsContainTheKeywords = asset.tags.some((tag) =>
      searchOptions.keywords.includes(tag)
    );
    matched = matched && (isContainSearchKeywords || isTagsContainTheKeywords);
  } else if (!isEmpty(searchOptions.categories)) {
    const isContainSearchedCategories = asset.categories.some((cate) =>
      searchOptions.categories.includes(cate)
    );
    matched = matched && isContainSearchedCategories;
  }

  return matched;
};

export const syncSearchAssetWithCache = (
  searchOptions: SearchAssetOptions,
  data: { assets: IAsset[]; totalCount: number },
  assetsInStore: IAsset[]
) => {
  const allCachedAssets = Object.values(cachedUpdatedAssets).map(
    (cache) => cache.post
  );

  allCachedAssets.forEach((cachedUpdatedAsset) => {
    const isAfterUpdateAssetMatched = isMatched(
      cachedUpdatedAsset,
      searchOptions
    );
    const isBeforeUpdateMatched = isMatched(
      cachedUpdatedAssets[cachedUpdatedAsset.id].prev,
      searchOptions
    );

    if (isAfterUpdateAssetMatched) {
      if (
        isBeforeUpdateMatched ||
        data.assets.find((a) => a.id === cachedUpdatedAsset.id)
      ) {
        return;
      }
      const isInStore = assetsInStore.find(
        (a) => a.id === cachedUpdatedAsset.id
      );
      if (!isBeforeUpdateMatched && !isInStore) {
        data.totalCount += 1;
        data.assets = [cachedUpdatedAsset].concat(data.assets);
      }
      return;
    }
    if (isBeforeUpdateMatched) {
      if (data.totalCount >= 1) {
        data.totalCount -= 1;
        data.assets = data.assets.filter((a) => a.id !== cachedUpdatedAsset.id);
      }
    }
  });

  if (data.totalCount < data.assets.length + assetsInStore.length) {
    data.totalCount === data.assets.length;
  }
  return data;
};

export const searchAssets = async (
  searchOptions: SearchAssetOptions
): Promise<{ assets: IAsset[]; totalCount: number }> => {
  const client = await apigClient(prodBaseUrlV2);
  const searchPayload = {
    keywords: !isEmpty(searchOptions.keywords)
      ? searchOptions.keywords.join(' ')
      : undefined,
    size: searchOptions.size || 20,
    startIndex: searchOptions.startIndex || 0,
    userId: searchOptions.userId,
    sortKey: searchOptions?.sortKey,
    sortOrder: searchOptions?.sortOrder,
    categories: searchOptions?.categories,
    assetIds: searchOptions?.assetIds,
    assetTypes: searchOptions?.assetTypes,
    status: searchOptions?.status
  };
  const retrievePath = `/libraries/v2/assets/byUserId`;
  const retrieveResult = await client.invokeApi(
    {},
    retrievePath,
    'POST',
    {},
    searchPayload
  );
  return retrieveResult.data;
};

export const deleteAsset = async (assetId: string) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/libraries/v1/assets/${assetId}`;
  const method = 'DELETE';
  const result = await client.invokeApi({}, path, method, {});
  return result.data;
};

export const deleteAssets = async (assetIDs: string[]) => {
  return Promise.all(assetIDs.map((id) => deleteAsset(id)));
};

export const getAsset = async (id: string): Promise<IAsset> => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/libraries/v1/assets/${id}`;
  const method = 'GET';
  const result = await client.invokeApi({}, path, method, {});
  return result.data;
};

export const getConvertingStatus = async (
  brand: string,
  assetId: string
): Promise<
  | {
      key: string;
      metadata: {
        converted: boolean;
        [key: string]: string | boolean | number;
      };
      pageBucket: string;
      pages: string[];
    }[]
  | undefined
> => {
  const uploadedFilesRequest = {
    assetId,
    brand
  };

  const client = await apigClient(prodBaseUrlV2);

  const retrievePath = `/libraries/v1/assets/uploadedFiles`;
  const retrieveResult = await client.invokeApi(
    {},
    retrievePath,
    'POST',
    {},
    uploadedFilesRequest
  );
  return retrieveResult.data;
};
