import { first, last } from 'lodash';
import { IBrandSetup } from '../../../interfaces';

const inspifyFont = 'Montserrat';

export const getFontName = (url = '') => {
  return decodeURIComponent(first(last(url.split('/')).split('.')));
};

export const getFontFormatFromUrl = (url = '') => {
  const ext = decodeURIComponent(last(url.split('.')));
  if (ext?.toLowerCase() === 'woff') {
    return 'woff';
  }
  if (ext?.toLowerCase() === 'woff2') {
    return 'woff2';
  }
  return 'truetype';
};

const CustomFont: React.FC<{ fontUrl: string; fontWeight: string }> = ({
  fontUrl
}) => {
  if (!fontUrl) {
    return;
  }

  return (
    <style jsx global>{`
      @font-face {
        font-family: ${getFontName(fontUrl)};
        src: url('${fontUrl}') format('${getFontFormatFromUrl(fontUrl)}');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }
    `}</style>
  );
};
const BrandCustomStyle = ({ brandSetup }: { brandSetup: IBrandSetup }) => (
  <>
      {brandSetup?.theme?.font?.bodyFontUrl && (
        <CustomFont
          fontUrl={brandSetup.theme.font.bodyFontUrl}
          fontWeight={brandSetup.theme.font.bodyFont}
        />
      )}

      {brandSetup?.theme?.font?.headerFontUrl && (
        <CustomFont
          fontUrl={brandSetup.theme.font.headerFontUrl}
          fontWeight={brandSetup.theme.font.headerFont}
        />
      )}

    <style jsx global>{`
      body {
        font-family: ${getFontName(brandSetup?.theme?.font?.bodyFontUrl) ||
        inspifyFont} !important;
      }
      header,
      .header,
      .header-font {
        font-family: ${getFontName(brandSetup?.theme?.font?.headerFontUrl) ||
        inspifyFont} !important;
      }
    `}</style>
  </>
);

export default BrandCustomStyle;
