export const DID_CREATE_FOLDER = 'DID_CREATE_FOLDER';
export const DID_LOAD_FOLDER = 'DID_LOAD_FOLDER';

export const DID_ADD_FOLDER_KEY = 'DID_ADD_FOLDER_KEY';
export const DID_DELETE_FOLDER_KEY = 'DID_DELETE_FOLDER_KEY';
export const DID_EDIT_FOLDER_KEY = 'DID_EDIT_FOLDER_KEY';


export const DID_SELECT_FOLDER_KEY = 'DID_SELECT_FOLDER_KEY';
export const DID_EXPAND_FOLDER_KEY = 'DID_EXPAND_FOLDER_KEY';

export const DID_MOVE_ELEMENT_TO_FOLDER_KEY = 'DID_MOVE_ELEMENT_TO_FOLDER_KEY';
export const DID_COPY_ELEMENT_TO_FOLDER_KEY = 'DID_COPY_ELEMENT_TO_FOLDER_KEY';
export const DID_DELETE_ELEMENT_FROM_FOLDER_KEY = 'DID_DELETE_ELEMENT_FROM_FOLDER_KEY';
