import { AxiosPromise } from 'axios';
import { IAccount, IBrandSetupResponse } from '../interfaces';
import httpClient from './http';

export const getAccountBrandSetup = (): AxiosPromise<{
  account: IAccount;
  brand: IBrandSetupResponse;
  step: number;
}> => httpClient.get(`/api/brand/getSetup`);

export const setupBrand = (
  payload
): AxiosPromise<{
  account: IAccount;
  brand: IBrandSetupResponse;
}> => {
  return httpClient.post(`/api/brand/update`, payload);
};

export const uploadBrandAsset = (payload: {
  file: File;
  path:
    | 'logo/light'
    | 'logo/dark'
    | 'font/body'
    | 'font/header'
    | 'video/intro'
    | 'video/outro'
    | 'image/bg'
    | 'image/countdown'
    | 'image/cover_photo'
    | 'image/meet_cover_photo'
    | 'image/og';
  brand;
}): AxiosPromise<{ url: string }> => {
  const { file, path, brand } = payload;
  const form = new FormData();
  form.append('file', file);
  form.append('filename', file.name);
  form.append('type', file.type);
  form.append('path', path);
  form.append('brand', brand);
  return httpClient.post('/api/brand/uploadAssets', form);
};

export const removeBrandAssets = (assetUrl: string, bucket = '') => {
  return httpClient.post('/api/brand/removeAssets', { assetUrl, bucket });
};

export const updateBrand = (
  id: string,
  brand_url: string,
  update
): AxiosPromise<IBrandSetupResponse> => {
  return httpClient.post(`/api/brand/branding`, { id, brand_url, update });
};

export const getBrandingSetup = (
  host: string
): AxiosPromise<IBrandSetupResponse> => {
  return httpClient.get(`/api/brand/branding/${encodeURIComponent(host)}`);
};

export const uploadBrandVirtualBackground = (payload: {
  file: File;
  brandId: string;
}): AxiosPromise<{ url: string }> => {
  const { file, brandId } = payload;
  const form = new FormData();
  form.append('file', file);
  form.append('filename', file.name);
  form.append('type', file.type);
  form.append('brand', brandId);
  form.append('alc', 'public-read');
  return httpClient.post('/api/brand/virtualBackground', form);
};

export const getBrandVirtualBackground = (brandId: string) => {
  return httpClient.get(`/api/brand/virtualBackground?brandId=${brandId}`);
};

export const deleteBrandVirtualBackground = (path: string) => {
  return httpClient.delete(`/api/brand/virtualBackground?path=${path}`);
};
