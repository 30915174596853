import {
  allBrands,
  assetBaseUrl,
  chopardBrandId,
  gustoLuxeBrandId,
  iwcBrandId,
  jlcBrandId,
  louboutinBrandId,
  rduBrandId,
  tiffanyBrandId,
  vacBrandId
} from '../../../config';
import { IBrandSetup } from '../../../interfaces';
import { assetBasePath } from '../../config';

export interface ThemeColor {
  primaryColor?: string;
  accent?: string;
  light?: string;
  grey?: string;
  dark?: string;
  btnPrimary?: string;
  btnPrimaryText?: string;
  nudgeLightMode?: boolean;
  lightHeader?: boolean;
  nudgeBackgroundUrl?: string;
}

export const themeColor: { [key: string]: ThemeColor } = {
  [iwcBrandId]: {
    accent: '#B7A56D',
    light: '#fff',
    grey: '#efefef',
    dark: '#000'
  },
  [jlcBrandId]: {
    accent: '#b7a98e',
    light: '#f3f1ed',
    grey: '#e4e1db',
    dark: '#3a3935'
  },
  [rduBrandId]: {
    accent: '#999',
    light: '#fff',
    grey: '#eaeaea',
    dark: '#000'
  },
  [louboutinBrandId]: {
    accent: '#ccc',
    btnPrimary: '#EE1F25',
    btnPrimaryText: '#fff'
  },
  [chopardBrandId]: {
    nudgeLightMode: true,
    nudgeBackgroundUrl: `${assetBaseUrl}/404_background.jpg`
  },
  [tiffanyBrandId]: {
    nudgeLightMode: true,
    accent: '#0ABAB5',
    btnPrimary: '#0ABAB5',
    btnPrimaryText: '#fff'
  },
  [vacBrandId]: {
    accent: '#c3a07d',
    light: '#fff',
    grey: '#f5f5f5',
    dark: '#383838',
    nudgeLightMode: true,
    lightHeader: true
  },
  [gustoLuxeBrandId]: {
    accent: '#000',
    light: '#fff',
    grey: '#efefef',
    dark: '#000',
    btnPrimary: '#000',
    btnPrimaryText: '#000',
    nudgeLightMode: false
  },
  default: {
    accent: '#B7A56D',
    light: '#fff',
    grey: '#efefef',
    dark: '#000',
    btnPrimary: '#B7A56D',
    btnPrimaryText: '#000',
    nudgeLightMode: false
  }
};

export const mobileBreakPoint = 820;

export const getThemeColor = (brandId: string): ThemeColor => ({
  primaryColor: themeColor?.[brandId]?.primaryColor,
  accent: themeColor?.[brandId]?.accent || themeColor.default.accent,
  light: themeColor?.[brandId]?.light || themeColor.default.light,
  grey: themeColor?.[brandId]?.grey || themeColor.default.grey,
  dark: themeColor?.[brandId]?.dark || themeColor.default.dark,
  btnPrimary:
    themeColor?.[brandId]?.btnPrimary ||
    themeColor?.[brandId]?.accent ||
    themeColor.default.btnPrimary,
  btnPrimaryText:
    themeColor?.[brandId]?.btnPrimaryText || themeColor.default.btnPrimaryText,
  nudgeLightMode:
    themeColor?.[brandId]?.nudgeLightMode || themeColor.default.nudgeLightMode,
  lightHeader: themeColor?.[brandId]?.lightHeader || false,
  nudgeBackgroundUrl: themeColor?.[brandId]?.nudgeBackgroundUrl || null
});

export const getAssetPath = (brandId: string | undefined) => {
  if (brandId) return `${assetBasePath}/${brandId}`;
  return `${assetBasePath}/default`;
};
export const getCoverImg = (
  brandId: string | undefined,
  brandSetup: IBrandSetup | undefined
) => {
  const isNotPreConfigured = allBrands.find(
    (brand) => brandId === brand.id
  )?.isNotPreConfigured;
  const mobile = isNotPreConfigured
    ? brandSetup?.coverPhoto ||
      'https://inspify-panos.s3-ap-southeast-1.amazonaws.com/advisor_hub/1bc913e3-faf3-47c3-a097-074905a292fd/login-m.jpg'
    : undefined;
  const desktop = isNotPreConfigured
    ? brandSetup?.coverPhoto ||
      'https://inspify-panos.s3-ap-southeast-1.amazonaws.com/advisor_hub/1bc913e3-faf3-47c3-a097-074905a292fd/login.jpg'
    : undefined;
  return { mobile, desktop };
};
export const isBrandNotPreConfigured = (brandId: string | undefined) => {
  return allBrands.find((brand) => brandId === brand.id)?.isNotPreConfigured;
};
