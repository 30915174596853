import { isNil, omitBy } from 'lodash';
import { IAccount, IBrandSetup, IBrandSetupResponse } from '../../interfaces';

export const mapBrandSetupRes = (
  brandRes: IBrandSetupResponse
): IBrandSetup => {
  const brand: IBrandSetup = {
    id: brandRes.id,
    brandName: brandRes.brand_name,
    brandUrl: brandRes.brand_url,
    coverPhoto: brandRes.cover_photo,
    meetCoverPhoto: brandRes.meet_cover_photo,
    video: {
      intro: brandRes.intro_video,
      outro: brandRes.outro_video
    },
    theme: {
      color: {
        accent: brandRes.secondary_color || brandRes.accent_color,
        link: brandRes.link_color,
        button: brandRes.button_color,
        primary: brandRes.primary_color || '',
        secondary: brandRes.secondary_color || ''
      },
      font: {
        headerFont: brandRes.header_font,
        headerFontUrl: brandRes.header_font_url,
        bodyFont: brandRes.body_font,
        bodyFontUrl: brandRes.body_font_url
      },
      logo: {
        dark: brandRes.dark_logo,
        light: brandRes.light_logo
      }
    },
    ogImage: brandRes.og_image,
    countdownBg: brandRes.countdown_page_background,
    meetingBackgroundLandscape: brandRes.meeting_background_landscape,
    meetingBackgroundPortrait: brandRes.meeting_background_portrait,
    city: brandRes.city,
    address: brandRes.address,
    country: brandRes.country,
    state: brandRes.state,
    zipCode: brandRes.zip_code,
    parentBrandId: brandRes.parent_brand_id
  };
  return brand;
};

export const mapAccountBrandToUpdatePayload = (
  _account: IAccount,
  _brand: IBrandSetup,
  step?: number
) => {
  const account = _account || {};
  const brand = _brand;
  const brandUrl = brand.brandUrl;
  const accountPayload = {
    id: account.id,
    contactId: account.contactId,
    brandName: brand.brandName,
    brand: brand.id,
    contactJobTitle: account.jobTitle,
    teams: account.teams,
    createdAt: account.createdAt,
    createdBy: account.createdBy,
    modifiedAt: account.modifiedAt,
    modifiedBy: account.modifiedBy,
    space: account.space,
    spaceName: account.spaceName,
    stripeCustomerId: account.stripeCustomerId,
    stripePackage: account.stripePackage,
    stripeProduct: account.stripeProduct,
    stripeSubscriptionId: account.stripeSubscriptionId
  };

  const brandPayload = {
    id: brand.id,
    brand_name: brand.brandName,
    brand_url: brandUrl,
    address: brand.address,
    city: brand.city,
    state: brand.state,
    country: brand.country,
    cover_photo: brand.coverPhoto,
    meet_cover_photo: brand.meetCoverPhoto,
    light_logo: brand.theme?.logo?.light,
    dark_logo: brand.theme?.logo?.dark,
    button_color: brand.theme?.color?.button,
    accent_color: brand.theme?.color?.accent,
    link_color: brand.theme?.color?.link,
    primary_color: brand.theme?.color?.primary,
    secondary_color: brand.theme?.color?.secondary,
    header_font: brand.theme?.font?.headerFont,
    header_font_url: brand.theme?.font?.headerFontUrl,
    body_font: brand.theme?.font?.bodyFont,
    body_font_url: brand.theme?.font?.bodyFontUrl,
    intro_video: brand?.video?.intro,
    outro_video: brand?.video?.outro,
    zip_code: brand?.zipCode,
    og_image: brand?.ogImage,
    countdown_page_background: brand?.countdownBg,
    meeting_background_landscape: brand?.meetingBackgroundLandscape,
    meeting_background_portrait: brand?.meetingBackgroundPortrait
  };

  const accountOnboarding = {
    account: omitBy(accountPayload, isNil),
    brand: omitBy(brandPayload, isNil),
    avatarPicture: account?.avatar || '',
    step: step || 2
  };

  return accountOnboarding;
};
