import { IStore } from '../../../interfaces';
import { apigClient } from '../aws';

export const getStoreById = async (storeId: string): Promise<IStore> => {
  const client = await apigClient();
  const path = `/stores/v2/${encodeURIComponent(storeId)}`;
  const method = 'GET';
  const result = await client.invokeApi({}, path, method, {});
  return result.data;
};

export const getStoresByBrandId = async (brandId: string): Promise<IStore[]> => {
  const client = await apigClient();
  const path = `/stores/v2/byBrandId/${brandId}`;
  const method = 'GET';
  const result = await client.invokeApi({}, path, method, {});
  return result.data;
}