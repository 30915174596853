import { IFolderKey } from './../../interfaces/index';
import { generateV4UUID } from './../../utils/identityGenerator';
import { uniq, sortBy, last, isEmpty, orderBy } from 'lodash';
import { IFolder, IFolderContext } from '../../interfaces';

export const SHARED_TEAM_TYPE = 'team';
export const FAVORITE_FOLDER = '/FAVORITES/';
export const TRASH_FOLDER = '/RECYCLE_BIN/';
export const SHARED_ITEMS = '/SHARED_ITEMS/';
export const SHARED_TEAM_ITEMS = '/SHARED_ITEMS/__Teams/';
export const COLLABORATED_ITEMS = '/COLLABORATED_ITEMS/';
export const SHARED_TEAM_FOLDER_ID = 'sharedTeamFolderId';
export const UNCATEGORIZED_FOLDER = '/UNCATEGORIZED/';

export const DEFAULT_ASSET_LIBRARY_FOLDERS = [
  TRASH_FOLDER,
  UNCATEGORIZED_FOLDER,
  '/Branding/',
  '/Branding/Fonts',
  '/Branding/Logos',
  '/Branding/Backgrounds',
  '/Branding/Videos',
  '/Elements/',
  '/Elements/Icons',
  '/Elements/Shapes',
  '/Elements/Logos',
  '/Marketing/',
  '/Products/',
  '/Feature Images/',
  '/Background/'
];

export const getRootFolderKeysFromList = (
  folders: IFolderKey[]
): IFolderKey[] => getFolderKeysByLevel(1, folders);

export const getFolderKeysByLevel = (
  level: number,
  folders: IFolderKey[]
): IFolderKey[] => {
  const folderList = folders.map((f) => f.key);
  const levelList = folderList.filter(
    (f) => f.split('/').filter((f2) => !!f2).length === level
  );
  if (levelList.length === 0) return [];
  return sortBy(levelList, (s) => s.toLocaleLowerCase()).map((f) =>
    folders.find((f2) => f2.key === f)
  );
};

export const getSubFolderKeysByParent = (
  id: string,
  folders: IFolderKey[]
): IFolderKey[] => {
  const parent = folders.find((f) => f.id === id) || { id: '/', key: '/' };
  const folderList = folders.map((f) => f.key);

  const allChildren = folderList.filter((folder) =>
    folder?.startsWith(parent?.key)
  );

  const uniqueChildren = uniq(
    allChildren.filter((f) => !!f && f !== parent.key)
  );
  return sortBy(uniqueChildren).map((f) => folders.find((f2) => f2.key === f));
};

const getCurrentFolderKeyLevel = (key: string) =>
  key?.split('/').filter((f) => !!f).length || 0;

export const getDirectSubFolder = (id: string, folders: IFolderKey[]) => {
  if (!id || id === '/') return getFolderKeysByLevel(1, folders);

  const currentFolder = folders.find((f) => f.id === id);

  if (!currentFolder) return [];

  const currentFolderLevel = getCurrentFolderKeyLevel(currentFolder?.key);
  const subFolders = getSubFolderKeysByParent(id, folders);
  return getFolderKeysByLevel(currentFolderLevel + 1, subFolders);
};

const getTrashKey = (allKeys: IFolderKey[]) =>
  allKeys.find((f) => f.key === TRASH_FOLDER);

const getFavoritesKey = (allKeys: IFolderKey[]) =>
  allKeys.find((f) => f.key === FAVORITE_FOLDER);

const getSharedItemsKey = (allKeys: IFolderKey[]) =>
  allKeys.find((f) => f.key === SHARED_ITEMS);

export const isTrashKey = (key: string, allKeys: IFolderKey[]) =>
  getTrashKey(allKeys)?.id === key || false;
const isFavoriteKey = (key: string, allKeys: IFolderKey[]) =>
  getFavoritesKey(allKeys)?.id === key || false;

export const isSharedItemsKey = (id: string, allKeys: IFolderKey[]) =>
  getSharedItemsKey(allKeys)?.id === id || false;

const isInFolderByKey = (id: string, key: string, allKeys: IFolderKey[]) => {
  const myKey = allKeys?.find((f) => f.id === id);
  if (!myKey) return false;
  return myKey.key.startsWith(key);
};

export const isInSharedItemsFolder = (id: string, allKeys: IFolderKey[]) =>
  isInFolderByKey(id, SHARED_ITEMS, allKeys);

export const isInCollaboratedItemsFolder = (
  id: string,
  allKeys: IFolderKey[]
) => isInFolderByKey(id, COLLABORATED_ITEMS, allKeys);

export const getOriginPathKey = (
  id: string,
  folders: IFolderKey[]
): IFolderKey[] => {
  const child = folders.find((f) => f.id === id);

  if (!child) return [{ id: '/', key: '/' }];

  if (isTrashKey(id, folders) || isFavoriteKey(id, folders) || id === '/')
    return [child];

  const key = child.key;
  const pathList = key.split('/').filter((f) => !!f);
  const parentList = [];

  let currentParent = '/';

  pathList.forEach((p, i) => {
    const newParent = currentParent + p + '/';
    parentList[i] = newParent;
    currentParent = newParent;
  });

  return sortBy(parentList)
    .filter((f1) => f1 !== child.key)
    .map((f2) => folders.find((f3) => f3.key === f2));
};

export const getParentFolderKey = (
  id: string,
  folders: IFolderKey[]
): IFolderKey => last(getOriginPathKey(id, folders));

export const isSpecialFolders = (folder: string): boolean =>
  [TRASH_FOLDER, FAVORITE_FOLDER, SHARED_ITEMS, '/'].includes(folder);

export const addNewKeyToFolder = (
  newKey: string,
  currentKeys: IFolderKey[]
): IFolderKey[] => [
  ...(currentKeys || []),
  {
    id: generateV4UUID(),
    key: newKey
  }
];

export const removeKeyFromFolder = (
  keyId: string,
  currentKeys: IFolderKey[]
) => {
  if (!keyId) return currentKeys;
  return currentKeys.filter((f) => f.id !== keyId);
};

export const mapFolderStateToPayload = ({
  folder,
  uuid,
  context,
  userId
}: {
  folder?: IFolder;
  uuid: string;
  context: IFolderContext;
  userId: string;
}): IFolder => ({
  id: folder?.id || generateV4UUID(),
  uuid: folder?.uuid || uuid,
  context: folder?.context || context,
  keys: folder?.keys || [],
  createdBy: folder?.createdBy || userId,
  createdAt: folder?.createdAt || new Date().toISOString(),
  modifiedBy: folder?.createdAt ? userId : '',
  modifiedAt: folder?.createdAt ? new Date().toISOString() : ''
});

export const getFolderKeyPathName = (label: string) => {
  if (label === TRASH_FOLDER) {
    return 'Deleted';
  }
  if (label === FAVORITE_FOLDER) {
    return 'Favorites';
  }
  if (label?.startsWith(SHARED_ITEMS)) {
    return ('Shared' + label?.replace(SHARED_ITEMS, '/'))?.replace(
      '/__Teams/',
      '/Teams/'
    );
  }
  if (label?.startsWith(COLLABORATED_ITEMS)) {
    return 'Collaborations';
  }
  return 'Overview' + label?.slice(0, -1);
};

export const isLastChildFolder = (id: string, folders: IFolderKey[]) => {
  const subFolderLength = getSubFolderKeysByParent(id, folders)?.length || 0;
  if (id === '/' && subFolderLength === 2) return true;
  return !subFolderLength;
};

export const getFolderLabel = (path: string) => {
  if (path === TRASH_FOLDER) return 'Deleted';
  if (path === FAVORITE_FOLDER) return 'Favorites';
  if (path === SHARED_ITEMS) return 'Shared';
  if (path === COLLABORATED_ITEMS) return 'Collaboration';
  if (path === SHARED_TEAM_ITEMS) return 'Teams';
  return path
    ?.split('/')
    .filter((f) => !!f)
    .pop();
};

export const renameKeyInFolder = (
  newKey: string,
  keyId: string,
  allKeys: IFolderKey[]
): IFolderKey[] => {
  const currentKey = allKeys.find((f) => f.id === keyId);
  const currentKeyLabel = currentKey?.key;

  return allKeys.map((f) => {
    if (f.key.startsWith(currentKeyLabel)) {
      return { id: f.id, key: f.key.replace(currentKeyLabel, newKey) };
    } else {
      return f;
    }
  });
};

export const getElementsInFolderKey = (
  key: string,
  allKeys: IFolderKey[],
  allElements: { [key: string]: string[] }
): string[] => {
  const subFolderKeys = (getSubFolderKeysByParent(key, allKeys) || []).map(
    (f) => f.id
  );

  const trashElements = allElements[getTrashKey(allKeys)?.id] || [];

  const elementsInMyKey = allElements[key] || [];

  let elementsInSubKey = [];

  if (key === '/') {
    allKeys
      .map((k) => k.id)
      .forEach((id) => {
        if (allElements[id]?.length) {
          elementsInSubKey = [...elementsInSubKey, ...allElements[id]];
        }
      });

    return uniq(elementsInSubKey);
  }

  if (isTrashKey(key, allKeys)) {
    return trashElements;
  }

  subFolderKeys.forEach((key) => {
    if (allElements[key]?.length) {
      elementsInSubKey = [...elementsInSubKey, ...allElements[key]];
    }
  });

  return [...elementsInMyKey, ...elementsInSubKey].filter(
    (e) => !trashElements.includes(e)
  );
};

export const getKeyIdFromElementId = (
  elementId: string,
  allElements: { [key: string]: string[] },
  excludeKeyId?: string[],
  activeFolderKey?: string
) => {
  const allKeys = uniq(Object.keys(allElements || {}).filter((key) => {
    const elements = allElements[key];
    return elements.includes(elementId) && !excludeKeyId?.includes(key);
  }));

  if (allKeys.length > 1 && activeFolderKey) {
    if(allKeys.includes(activeFolderKey)) return activeFolderKey
    return allKeys[0]
  }

  return allKeys[0]
};

export const filterElementsInKeyByItems = (
  elementsInKey: { [key: string]: string[] },
  items: string[]
) => {
  return Object.keys(elementsInKey || {}).reduce((acc, key) => {
    const elements = elementsInKey[key] || [];
    return {
      ...acc,
      [key]: elements.filter((e) => (items || []).includes(e))
    };
  }, {});
};

export const removeEmptyFolderKeys = (
  folderKeys: IFolderKey[],
  elements: { [key: string]: string[] }
) => {
  return folderKeys.filter((f) => {
    const subFolders = getSubFolderKeysByParent(f.id, folderKeys);
    return (
      isTrashKey(f.id, folderKeys) ||
      isFavoriteKey(f.id, folderKeys) ||
      !!elements?.[f.id]?.length ||
      subFolders?.some((s) => !!elements?.[s.id]?.length)
    );
  });
};

export const getLevelOfFolderKey = (key = '') => {
  return key.replace(/\/$/, '').split('/').length - 1;
};

export const getLabelOfFolderKey = (key = '') => {
  return last(key.replace(/\/$/, '').split('/'));
};

export const sortFolderKeys = (keys: IFolderKey[] = []): IFolderKey[] => {
  const sortedKeys = [];

  const groupInToTree = (parentCategories) => {
    parentCategories.forEach((cate) => {
      sortedKeys.push(cate);
      const level = getLevelOfFolderKey(cate.key);
      const directChildren = orderBy(
        keys.filter(
          (ct) =>
            ct.key.startsWith(`${cate.key.replace(/\/$/, '')}/`) &&
            getLevelOfFolderKey(ct.key) - level === 1
        ),
        (k) => k.key.toLowerCase(),
        'asc'
      );
      if (isEmpty(directChildren)) {
        return;
      }
      groupInToTree(directChildren);
    });
  };
  const rootChildren = orderBy(
    keys.filter((cate) => getLevelOfFolderKey(cate.key) === 1),
    (k) => k.key.toLowerCase()
  );
  groupInToTree(rootChildren);

  return sortedKeys;
};

export const hasUserSubFolder = (allKeys: IFolderKey[]) =>
  allKeys.some(
    (key) =>
      !isSpecialFolders(key.key) &&
      !key.key.startsWith(SHARED_ITEMS) &&
      key.key !== '/COLLABORATED_ITEMS/'
  );
