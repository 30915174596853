import { format, startOfDay } from 'date-fns';
import { ISessionDetails, ISocialMedia } from './../../interfaces/index';
import { addDays } from 'date-fns';
import { isProd } from '../../config';
import httpClient from '../../clientSideServices/http';
/* eslint-disable no-var */
declare global {
  var FB: any;
}
/* eslint-enable no-var */

export const facebookAppId = '1313004749534241';
export const facebookAuthUrl = isProd
  ? 'https://auth.inspify.io'
  : 'https://auth-dev.inspify.io';

export const initFacebookSdk = (onLoad?: () => void) => {
  window['fbAsyncInit'] = function () {
    FB.init({
      appId: facebookAppId,
      cookie: true,
      xfbml: true,
      version: 'v14.0'
    });
  };
  // inject sdk.js
  (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    const js = d.createElement(s) as HTMLScriptElement;
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
    js.onload = onLoad;
  })(document, 'script', 'facebook-jssdk');
};

export interface IFacebookUser {
  id: string;
  name: string;
}

export interface IFacebookAPIRes {
  data: {
    [key: string]: any;
  };
}

export enum FacebookConnectionStatus {
  SUCCESS = 'FB.SUCCES',
  ERROR = 'FB.ERROR',
  NOT_AUTHORIZED = 'FB.NOT_AUTHORIZED',
  ALREADY_CONNECTED = 'FB.ALREADY_CONNECTED',
  FORCE_SWITCH = 'FB.FORCE_SWITCH_TO_USER'
}

export interface IFacebookPage {
  access_token: string;
  category: string;
  category_list: {
    id?: string;
    name?: string;
  };
  id: string;
  name: string;
  task: string[];
}

export interface IFacebookLoginResponse {
  authResponse: {
    accessToken: string;
    userID: string;
  };
  status: FacebookConnectionStatus;
}

export interface FBProfile {
  name: string;
  id: string;
  picture: {
    data: {
      url: string;
    };
  };
}

export interface IFacebookEvent {
  name: string;
  start_time: string;
  end_time: string;
  id: string;
  timezone: string;
  description: string;
}
export const getFacebookProfileByAccessToken = (
  token: string
): Promise<FBProfile> => {
  return new Promise((resolve, reject) => {
    FB.api(
      `/me?fields=id,name,picture.width(70)&access_token=${token}`,
      'GET',
      (res) => {
        if (res.id) resolve(res);
        else reject(res);
      }
    );
  });
};

export const mapStreamTokensToSocialMediaContacts = (
  streamToken: string
): ISocialMedia[] => {
  try {
    const socialMedia: ISocialMedia[] = JSON.parse(streamToken);
    if (!socialMedia || socialMedia.length <= 0) return [];
    return socialMedia;
  } catch (err) {
    console.log('Error parsing stream tokens', err);
    return [];
  }
};

export const mapSocialMediaContacsToStreamTokens = (
  socialMedias: ISocialMedia[]
): string => {
  return JSON.stringify(socialMedias);
};

export const getFacebookPageList = (
  accessToken: string
): Promise<IFacebookPage[]> => {
  return new Promise((resolve, reject) => {
    FB.api(`/me/accounts?access_token=${accessToken}`, 'GET', (res) => {
      if (res.data) resolve(res.data);
      else reject(res);
    });
  });
};

export const mapFacebookEventsToSessionDetails = (
  event: IFacebookEvent,
  token: string,
  id: string
): ISessionDetails => {
  const startTime = new Date(event.start_time);
  const endTime = event.end_time ? new Date(event.end_time) : '';
  return {
    id: event.id,
    topic: event.name,
    description: event.description,
    startDate: format(startTime, 'MM-dd-yyyy'),
    endDate: endTime ? format(endTime, 'MM-dd-yyyy') : '',
    startTime: format(startTime, 'HH:mm'),
    endTime: endTime ? format(endTime, 'HH:mm') : '',
    timezone: event.timezone,
    sessionType: 'facebook',
    ownerId: id,
    ownerToken: token
  };
};

export const getPageEvents = (
  token: string,
  id: string,
  date: string
): Promise<ISessionDetails[]> => {
  const startDate = startOfDay(new Date(date));
  const startDateUnix = Math.floor(startDate.getTime() / 1000);
  const endDate = addDays(startDate, 1);
  const endDateUnix = Math.floor(endDate.getTime() / 1000);
  const fields = [
    'timezone',
    'name',
    'start_time',
    'end_time',
    'description'
  ].join(',');
  const queryString = `&is_canceled=false&since=${startDateUnix}&until=${endDateUnix}`;
  return new Promise((resolve, reject) => {
    FB.api(
      `${id}/events?access_token=${token}&fields=${fields}&${queryString}`,
      'GET',
      (res) => {
        if (res.data)
          resolve(
            res.data.map((data) =>
              mapFacebookEventsToSessionDetails(data, token, id)
            )
          );
        else reject(res);
      }
    );
  });
};

export const exchangeFacebookToken = (
  socialMedia: ISocialMedia
): Promise<ISocialMedia> => {
  return httpClient
    .get(`/api/facebookToken?token=${socialMedia.token}`)
    .then((res) => ({
      ...socialMedia,
      token: res.data.access_token
    }));
};
