import { IFolder, IFolderContext } from '../../../interfaces';
import { prodBaseUrlV2 } from '../../config';
import { apigClient } from '../aws';

export const getFoldersByUuidAndContext = async (
  uuid: string,
  context: IFolderContext
): Promise<IFolder[]> => {
  const client = await apigClient(prodBaseUrlV2);
  const body = {
    uuid,
    context
  };
  const path = '/folders/v1/byUuidAndContext';
  const result = await client.invokeApi({}, path, 'POST', {}, body);
  return result.data;
};

export const saveFolder = async (folder: IFolder) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/folders/v1/`;
  const result = await client.invokeApi({}, path, 'POST', {}, folder);
  return result.data;
};

export const deleteFolder = async (id: string) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/folders/v1/${id}`;
  const result = await client.invokeApi({}, path, 'DELETE', {});
  return result;
};

export const moveElementToFolder = async (body: {
  elementId: string;
  elementType: IFolderContext;
  fromFolderId: string;
  fromKeyId: string;
  toFolderId: string;
  toKeyId: string;
}) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/folders/v1/elements/move`;
  const result = await client.invokeApi({}, path, 'POST', {}, body);
  return result;
};

export const copyElementToFolder = async (body: {
  elementId: string;
  elementType: IFolderContext;
  toFolderId: string;
  toKeyId: string;
}) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/folders/v1/elements/copy`;
  const result = await client.invokeApi({}, path, 'POST', {}, body);
  return result;
};

export const deleteElementInFolder = async (body: {
  elementId: string;
  elementType: IFolderContext;
  folderId: string;
  keyId: string;
}) => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/folders/v1/elements/delete`;
  const result = await client.invokeApi({}, path, 'POST', {}, body);
  return result;
};

export const getElementsInFolder = async (body: {
  elementType: IFolderContext;
  folderId: string;
  keyId: string;
}): Promise<string[]> => {
  const client = await apigClient(prodBaseUrlV2);
  const path = `/folders/v1/elements`;
  const result = await client.invokeApi({}, path, 'POST', {}, body);

  return result.data || [];
};
